import {
  Grid,
  IconButton,
  Paper,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import { Thread } from "./Thread";

import MessageInput from "./MessageInput";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  clearMessages,
  selectMessages,
  selectScenario,
  selectStreamedResponse,
  sendMessage,
} from "../store/conversationSlice";
import { useEffect } from "react";
import { ArrowBack, Refresh } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface ChatWindowProps {
  sx?: SxProps;
}

export const ChatWindow = ({ sx }: ChatWindowProps) => {
  const navigate = useNavigate();
  const messages = useAppSelector(selectMessages);
  const scenario = useAppSelector(selectScenario);
  const streamedResponse = useAppSelector(selectStreamedResponse);
  const dispatch = useAppDispatch();

  const assistantName = scenario?.personas?.assistant?.name;

  const goBack = () => {
    navigate("../configure");
  };

  const resetChat = () => {
    dispatch(clearMessages());
    dispatch(sendMessage());
  };

  useEffect(() => {
    dispatch(sendMessage());
  }, []);

  return (
    <Paper
      sx={{
        width: 1,
        height: 1,
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "stretch",
        ...sx,
      }}
    >
      <Stack direction="column">
        <Grid container alignItems="center">
          <Grid item xs={2} display="flex">
            <IconButton onClick={goBack}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" align="center" py={2}>
              {assistantName}
            </Typography>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="right">
            <IconButton onClick={resetChat}>
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>
      </Stack>
      <Thread messages={messages} partialResponse={streamedResponse} />
      <MessageInput
        onSendMessage={(message) => dispatch(sendMessage(message))}
      />
    </Paper>
  );
};
