import { Link, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      gap={2}
      alignItems="center"
      flexWrap="wrap"
      justifyContent="center"
    >
      <Typography variant="caption" color="text.secondary">
        {t("footer.copyright")}
      </Typography>
      <Link
        href="/terms-of-service"
        variant="caption"
        sx={{ color: "text.secondary", textDecoration: "none" }}
      >
        {t("footer.termsOfService")}
      </Link>
      <Link
        href="/privacy-policy"
        variant="caption"
        sx={{ color: "text.secondary", textDecoration: "none" }}
      >
        {t("footer.privacy")}
      </Link>
      <Link
        href="/faqs"
        variant="caption"
        sx={{ color: "text.secondary", textDecoration: "none" }}
      >
        {t("footer.faqs")}
      </Link>
    </Stack>
  );
};
