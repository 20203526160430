import {
  Checkbox,
  CircularProgress,
  Fab,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Scenario, useFormOptions } from "../../types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  clearMessages,
  selectScenario,
  setScenario,
} from "../../store/conversationSlice";
import { useLazyGenerateScenarioQuery } from "../../services/scenarios";
import { useEffect } from "react";
import { ArrowBack, AutoAwesome, Refresh } from "@mui/icons-material";
import { ErrorDialog } from "../../components/ErrorDialog";
import { selectUserId } from "../../store/appSlice";
import { LoadingButton } from "@mui/lab";
import { useLazySafeguardQuery } from "../../services/safeguard";
import { Trans, useTranslation } from "react-i18next";

type FormValues = {
  scenario: Scenario;
  acceptTerms: boolean;
};

const defaultScenario: Scenario = {
  personas: {
    user: {
      name: "",
      gender: "",
      biography: "",
    },
    assistant: {
      name: "",
      gender: "",
      biography: "",
    },
  },
  roleplay: {
    level: "dirty",
    scenario: "",
  },
};

export const ScenarioForm = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const scenario = useAppSelector(selectScenario);
  const userId = useAppSelector(selectUserId);
  const { t } = useTranslation();
  const { genders, roleplayLevel } = useFormOptions();

  const resetScenario = state?.resetScenario;
  const hideGenerate = state?.hideGenerate;

  const [
    triggerGenerateScenario,
    {
      data: generatedScenario,
      isFetching: isFetchingGeneratedScenario,
      isError: generationErrored,
    },
  ] = useLazyGenerateScenarioQuery();

  const [
    triggerCheckScenario,
    { isFetching: isCheckingScenario, data: checkScenarioResult },
  ] = useLazySafeguardQuery();

  const showProhibitedContentDialog =
    (!isCheckingScenario && checkScenarioResult?.prohibitedContent) ?? false;

  const isFetching = isFetchingGeneratedScenario || isCheckingScenario;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      scenario: scenario && !resetScenario ? scenario : defaultScenario,
      acceptTerms: false,
    },
  });

  const currentScenario = watch("scenario");

  const onSubmit = async (values: FormValues) => {
    const { data, isError } = await triggerCheckScenario({
      scenario: JSON.parse(JSON.stringify(currentScenario)),
    });

    const prohibitedContent = data?.prohibitedContent;

    if (!isError && prohibitedContent) {
      return;
    }

    dispatch(setScenario(values.scenario));
    dispatch(clearMessages());
    navigate("/chat");
  };

  useEffect(() => {
    if (!generatedScenario) {
      return;
    }

    setValue("scenario", generatedScenario);
  }, [generatedScenario]);

  return (
    <>
      <Stack gap={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="center">
          <Grid item xs={2} display="flex">
            <IconButton onClick={() => navigate("../build")}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" align="center" py={2}>
              {t("scenarioForm.title")}
            </Typography>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="right">
            <IconButton
              onClick={() => {
                reset({
                  scenario: defaultScenario,
                });
              }}
            >
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>

        <Typography>{t("scenarioForm.subtitle")}</Typography>

        <Typography variant="h5">
          {t("scenarioForm.yourCharacter.title")}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {t("scenarioForm.yourCharacter.subtitle")}
        </Typography>

        <Controller
          name="scenario.personas.user.name"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("scenarioForm.yourCharacter.name")}
              disabled={isFetching}
              {...field}
            />
          )}
        ></Controller>
        <Controller
          name="scenario.personas.user.gender"
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              exclusive
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              disabled={isFetching}
            >
              {genders.map(({ label, value }) => (
                <ToggleButton key={value} value={value}>
                  {label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        />
        <Controller
          name="scenario.personas.user.biography"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("scenarioForm.yourCharacter.biography")}
              multiline
              minRows={2}
              disabled={isFetching}
              {...field}
            />
          )}
        ></Controller>

        <Typography variant="h5" mt={2}>
          {t("scenarioForm.theirCharacter.title")}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {t("scenarioForm.theirCharacter.subtitle")}
        </Typography>
        <Controller
          name="scenario.personas.assistant.name"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("scenarioForm.theirCharacter.name")}
              disabled={isFetching}
              {...field}
            />
          )}
        ></Controller>
        <Controller
          name="scenario.personas.assistant.gender"
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              disabled={isFetching}
              exclusive
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
            >
              {genders.map(({ label, value }) => (
                <ToggleButton key={value} value={value}>
                  {label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        />
        <Controller
          name="scenario.personas.assistant.biography"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("scenarioForm.yourCharacter.biography")}
              multiline
              minRows={2}
              disabled={isFetching}
              {...field}
            />
          )}
        ></Controller>

        <Typography variant="h5" mt={2}>
          {t("scenarioForm.roleplay.title")}
        </Typography>
        <Controller
          name="scenario.roleplay.level"
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              disabled={isFetching}
              exclusive
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
            >
              {roleplayLevel.map(({ label, value }) => (
                <ToggleButton key={value} value={value}>
                  {label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        />
        <Controller
          name="scenario.roleplay.scenario"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("scenarioForm.roleplay.description")}
              multiline
              minRows={3}
              disabled={isFetching}
              {...field}
            />
          )}
        ></Controller>
        <Stack>
          <FormControlLabel
            control={
              <Checkbox
                disabled={isFetching}
                {...register("acceptTerms", {
                  required: t("scenarioForm.termsAndConditions.error"),
                })}
              />
            }
            label={
              <Typography>
                <Trans
                  i18nKey="scenarioForm.termsAndConditions.label"
                  components={[
                    <Link href="/terms-of-service" />,
                    <Link href="/privacy-policy" />,
                  ]}
                />
              </Typography>
            }
          />
          {errors.acceptTerms && (
            <FormHelperText error>{errors.acceptTerms.message}</FormHelperText>
          )}
        </Stack>

        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isFetching}
        >
          {t("scenarioForm.buttons.start")}
        </LoadingButton>
      </Stack>
      {!hideGenerate && (
        <Fab
          onClick={() =>
            triggerGenerateScenario({ scenario: currentScenario, userId })
          }
          variant="extended"
          sx={{ position: "fixed", bottom: 20, right: 20 }}
        >
          <AutoAwesome sx={{ mr: 1 }} />
          {isFetchingGeneratedScenario ? (
            <CircularProgress size={18} sx={{ color: "black" }} />
          ) : (
            t("scenarioForm.buttons.generate")
          )}
        </Fab>
      )}
      <ErrorDialog
        open={generationErrored}
        message={t("scenarioForm.errorMessage")}
      />
      <ErrorDialog
        open={showProhibitedContentDialog}
        message={t("scenarioForm.prohibitedContentMessage")}
      />
    </>
  );
};
