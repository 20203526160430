import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from "@sentry/react";
import "@fontsource-variable/raleway";
import "@fontsource/pacifico";
import "./index.css";
import "./i18n";

Sentry.init({
  dsn: "https://cc464dc2db444aac67cf2bb3525d7687@o4506962456215552.ingest.us.sentry.io/4506962592202752",
  integrations: [Sentry.browserTracingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/chaterotica\.app\/api/],
  enabled: process.env.NODE_ENV !== "development",
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
