import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import type { RootState } from ".";

import { v4 as uuidv4 } from "uuid";

export interface AppState {
  userId: string;
  language: string;
}

const initialState: AppState = {
  userId: uuidv4(),
  language: "en",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

export const { setLanguage } = appSlice.actions;

export const selectUserId = (state: RootState) => state.app.userId;
export const selectLanguage = (state: RootState) => state.app.language;

export default appSlice.reducer;
