import { Button, Link, Stack, Typography } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/Logo";
import { useTranslation } from "react-i18next";

export const Welcome = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Stack alignItems="center">
        <Logo />
      </Stack>
      <Typography>{t("welcome.blurb.0")}</Typography>
      <Typography>{t("welcome.blurb.1")}</Typography>
      <Typography mb={2}>{t("welcome.blurb.2")}</Typography>
      <Button
        variant="contained"
        onClick={() => {
          navigate("./build");
        }}
      >
        {t("welcome.getStartedButton")}
      </Button>
      <Link href="/faqs" variant="subtitle2" mt={2}>
        {t("welcome.faqLink")}
      </Link>
    </Stack>
  );
};
