import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { ChatWindow } from "../components/ChatWindow";

export const Chat = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack height={1} width={1} p={isMobile ? 0 : 5} alignItems="center">
      <ChatWindow sx={{ borderRadius: isMobile ? 0 : 6, maxWidth: 800 }} />
    </Stack>
  );
};
