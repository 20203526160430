import terms from "../data/en/terms.md";
import { Stack } from "@mui/material";
import { MarkdownFile } from "../components/MarkdownFile";
import { Logo } from "../components/Logo";

export const TermsOfService = () => {
  return (
    <Stack alignItems="center" sx={{ mt: 3 }}>
      <Stack alignItems="center">
        <Logo />
      </Stack>
      <Stack py={2} px={4} maxWidth={800}>
        <MarkdownFile filePath={terms} />
      </Stack>
    </Stack>
  );
};
