import { useTranslation } from "react-i18next";

interface FormOption {
  label: string;
  value: string;
}

export const useFormOptions = () => {
  const { t } = useTranslation();

  const genders: FormOption[] = t("scenarioForm.genders", {
    returnObjects: true,
  });
  const roleplayLevel: FormOption[] = t("scenarioForm.roleplayLevel", {
    returnObjects: true,
  });

  return { genders, roleplayLevel };
};
