import {
  createSlice,
  PayloadAction,
  Action,
  ThunkAction as ReduxThunkAction,
} from "@reduxjs/toolkit";

import type { RootState } from ".";
import { Message, Scenario } from "../types";
import { getChatResponse } from "../services/chat";
import { v4 as uuidv4 } from "uuid";

type ThunkAction = ReduxThunkAction<void, RootState, unknown, Action>;

export interface ConversationState {
  messages: Message[];
  streamedResponse: string;
  scenario?: Scenario;
  sessionId?: string;
}

const initialState: ConversationState = {
  messages: [],
  streamedResponse: "",
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Message>) => {
      state.messages = [...state.messages, action.payload];
    },
    updateStreamedResponse: (state, action: PayloadAction<string>) => {
      state.streamedResponse = state.streamedResponse + action.payload;
    },
    commitStreamedResponse: (state) => {
      if (!state.streamedResponse) {
        return;
      }

      state.messages = [
        ...state.messages,
        { role: "assistant", content: state.streamedResponse },
      ];

      state.streamedResponse = "";
    },
    setScenario: (state, action: PayloadAction<Scenario>) => {
      state.scenario = action.payload;
    },
    clearMessages: (state) => {
      state.messages = [];
      state.sessionId = undefined;
    },
    generateSessionId: (state) => {
      state.sessionId = uuidv4();
    },
  },
});

export const sendMessage =
  (content?: string): ThunkAction =>
  async (dispatch, getState) => {
    let { sessionId } = getState().conversation;

    if (!sessionId) {
      dispatch(generateSessionId());
      sessionId = getState().conversation.sessionId;
    }

    content && dispatch(addMessage({ role: "user", content }));

    const {
      conversation: { messages, scenario },
      app: { userId, language },
    } = getState();

    try {
      await getChatResponse(
        {
          messages,
          scenario: {
            ...scenario,
            roleplay: { ...scenario?.roleplay, language },
          } as Scenario,
          userId,
          sessionId,
        },
        (chunk) => {
          dispatch(updateStreamedResponse(chunk));
        }
      );

      dispatch(commitStreamedResponse());
    } catch (e) {
      dispatch(
        addMessage({ role: "system", content: "Something went wrong." })
      );
    }
  };

export const {
  addMessage,
  updateStreamedResponse,
  commitStreamedResponse,
  setScenario,
  clearMessages,
  generateSessionId,
} = conversationSlice.actions;

export const selectMessages = (state: RootState) => state.conversation.messages;
export const selectStreamedResponse = (state: RootState) =>
  state.conversation.streamedResponse;
export const selectScenario = (state: RootState) => state.conversation.scenario;

export default conversationSlice.reducer;
