import { ChatRequestBody } from "../../common/request";

export const getChatResponse = async (
  request: ChatRequestBody,
  onChunk?: (chunk: string) => void
) => {
  const response = await fetch("/api/chat", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(request),
  });

  const reader = response.body?.getReader();

  if (!response.ok || !reader) {
    throw new Error();
  }

  while (true) {
    const { done, value } = await reader.read();
    if (done) {
      break;
    }

    const chunk = new TextDecoder().decode(value);
    onChunk?.(chunk);
  }
};
