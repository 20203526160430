import { useEffect, useState } from "react";
import Markdown from "react-markdown";

interface MarkdownFileProps {
  filePath: string;
}

export const MarkdownFile = ({ filePath }: MarkdownFileProps) => {
  const [markdown, setMarkdown] = useState("");

  useEffect(() => {
    fetch(filePath)
      .then((response) => response.text())
      .then((text) => {
        setMarkdown(text);
      });
  }, [filePath]);

  return <Markdown>{markdown}</Markdown>;
};
