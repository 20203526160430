import { Stack, Typography } from "@mui/material";
import appLogo from "../assets/logo.png";
import { Link } from "react-router-dom";

export const Logo = () => (
  <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
    <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
      <img src={appLogo} height={64} />
      <Typography variant="h3" fontFamily="Pacifico">
        ChatErotica
      </Typography>
    </Stack>
  </Link>
);
