import { z } from "zod";

export const RoleplaySchema = z
  .object({
    level: z.enum(["clean", "dirty"]),
    scenario: z
      .string()
      .optional()
      .describe("The encounter, scene, and setting of the roleplay."),
    language: z
      .string()
      .optional()
      .describe("The ISO 639-1 language code to carry out the roleplay in."),
  })
  .partial();

export const PersonaSchema = z
  .object({
    name: z.string(),
    gender: z.string(),
    biography: z
      .string()
      .optional()
      .describe("A brief description of the character."),
  })
  .partial();

export const ScenarioSchema = z.object({
  personas: z.object({
    user: PersonaSchema.optional(),
    assistant: PersonaSchema.optional(),
  }),
  roleplay: RoleplaySchema,
});

export type Persona = z.infer<typeof PersonaSchema>;
export type Roleplay = z.infer<typeof RoleplaySchema>;
export type Scenario = z.infer<typeof ScenarioSchema>;
