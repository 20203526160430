import { Paper, Stack, Typography } from "@mui/material";
import { Message } from "../types";
import { grey, indigo } from "@mui/material/colors";

interface ThreadProps {
  messages: Message[];
  partialResponse?: string;
}

interface MessageBoxProps {
  message: Message;
  index?: number;
}

const MessageBox = ({ message, index }: MessageBoxProps) => {
  const isAssistant = message.role === "assistant";
  const isSystem = message.role === "system";

  return (
    <Paper
      key={index}
      sx={{
        alignSelf: isAssistant || isSystem ? "flex-start" : "flex-end",
        maxWidth: "75%",
        borderRadius: 2,
        flexShrink: 0,
        p: 1,
        backgroundColor: isAssistant || isSystem ? grey[800] : indigo[800],
      }}
    >
      <Typography fontStyle={isSystem ? "italic" : undefined}>
        {message.content}
      </Typography>
    </Paper>
  );
};

export const Thread = ({ messages, partialResponse }: ThreadProps) => (
  <Stack
    gap={2}
    p={2}
    sx={{ overflowY: "scroll", flexDirection: "column-reverse", flexGrow: 1 }}
  >
    {partialResponse && (
      <MessageBox
        key="partial-response"
        index={messages.length}
        message={{ role: "assistant", content: partialResponse }}
      />
    )}
    {messages
      .map((message, index) => {
        if (
          message.role !== "user" &&
          message.role !== "assistant" &&
          message.role !== "system"
        ) {
          return null;
        }
        return <MessageBox key={index} index={index} message={message} />;
      })
      .reverse()}
  </Stack>
);
