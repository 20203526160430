import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

interface ErrorDialogProps {
  open: boolean;
  message: string;
}

export const ErrorDialog = ({ open, message }: ErrorDialogProps) => {
  const [showError, setShowError] = useState(false);

  const closeErrorDialog = () => {
    setShowError(false);
  };

  useEffect(() => {
    setShowError(open);
  }, [open]);

  return (
    <Snackbar
      open={showError}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      autoHideDuration={6000}
      onClose={closeErrorDialog}
    >
      <Alert severity="error" variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};
