import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { GuardrailsRequest, GuardrailsResponse } from "../types";

export const safeguardApi = createApi({
  reducerPath: "safeguard",
  baseQuery: retry(fetchBaseQuery({ baseUrl: "/" }), {
    maxRetries: 3,
  }),
  endpoints: (builder) => ({
    safeguard: builder.query<GuardrailsResponse, GuardrailsRequest>({
      query: (body) => ({
        url: `api/safeguard`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useLazySafeguardQuery } = safeguardApi;
