import { z } from "zod";
import { ScenarioSchema } from "./scenario";

export const GuardrailsRequestSchema = z.object({
  scenario: ScenarioSchema,
});

export const GuardrailsResponseSchema = z.object({
  prohibitedContent: z.boolean(),
});

export type GuardrailsRequest = z.infer<typeof GuardrailsRequestSchema>;
export type GuardrailsResponse = z.infer<typeof GuardrailsResponseSchema>;
