import { Stack } from "@mui/material";
import { MarkdownFile } from "../components/MarkdownFile";
import { Logo } from "../components/Logo";
import { useTranslation } from "react-i18next";

import en from "../data/en/faqs.md";
import fr from "../data/fr/faqs.md";
import ja from "../data/ja/faqs.md";
import es from "../data/es/faqs.md";

const faqs: { [key: string]: string } = { en, fr, ja, es };

export const FAQs = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Stack alignItems="center" sx={{ mt: 3 }}>
      <Stack alignItems="center">
        <Logo />
      </Stack>
      <Stack py={2} px={4} maxWidth={800}>
        <MarkdownFile filePath={faqs[language]} />
      </Stack>
    </Stack>
  );
};
