import { MenuItem, Select, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectLanguage, setLanguage } from "../store/appSlice";
import { useParams } from "react-router-dom";

const languages = [
  {
    label: "🇺🇸 English",
    value: "en",
  },
  {
    label: "🇪🇸 Español",
    value: "es",
  },
  {
    label: "🇫🇷 Français",
    value: "fr",
  },
  {
    label: "🇯🇵 日本語",
    value: "ja",
  },
];

const availableLanguages = languages.map((l) => l.value);

export const LanguageSelect = () => {
  const dispatch = useAppDispatch();
  const language = useAppSelector(selectLanguage);
  const { i18n } = useTranslation();
  const { lang } = useParams();

  useEffect(() => {
    if (!lang || !availableLanguages.includes(lang)) {
      return;
    }

    dispatch(setLanguage(lang));
  }, [lang]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <Select
      label="Language"
      variant="standard"
      value={language}
      onChange={(event) => dispatch(setLanguage(event.target.value))}
      disableUnderline
      sx={{ opacity: 0.8 }}
    >
      {languages.map((language, idx) => (
        <MenuItem value={language.value} key={idx}>
          <Typography variant="caption">{language.label}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};
