import { PaletteMode } from "@mui/material";
import { amber } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

export const getTheme = (mode: PaletteMode = "light") =>
  createTheme({
    palette: {
      mode,
      primary: {
        main: amber[500],
      },
    },
    typography: {
      fontFamily: ["Raleway Variable", "sans-serif"].join(","),
    },
  });
