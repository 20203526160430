import { CssBaseline, ThemeProvider } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { getTheme } from "./theme";
import { Chat } from "./pages/Chat";
import { TermsOfService } from "./pages/TermsOfService";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { useAppSelector } from "./store/hooks";
import { selectUserId } from "./store/appSlice";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { FAQs } from "./pages/FAQ";
import { LandingPage } from "./pages/LandingPage";
import { Welcome } from "./pages/LandingPage/Welcome";
import { ScenarioWizard } from "./pages/LandingPage/ScenarioWizard";
import { ScenarioForm } from "./pages/LandingPage/ScenarioForm";

const router = createBrowserRouter([
  {
    path: "/:lang?/*",
    element: <LandingPage />,
    children: [
      {
        path: "",
        element: <Welcome />,
      },
      {
        path: "build",
        element: <ScenarioWizard />,
      },
      {
        path: "configure",
        element: <ScenarioForm />,
      },
      {
        path: "faqs",
        element: <FAQs />,
      },
    ],
  },
  {
    path: "/chat",
    element: <Chat />,
  },
  {
    path: "/terms-of-service",
    element: <TermsOfService />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
]);

export const App = () => {
  const userId = useAppSelector(selectUserId);

  useEffect(() => {
    if (!userId) {
      return;
    }

    Sentry.setUser({ id: userId });
  }, [userId]);

  return (
    <ThemeProvider theme={getTheme("dark")}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};
