import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { GenerateScenarioRequestBody, Scenario } from "../types";

export const scenarioApi = createApi({
  reducerPath: "generateScenario",
  baseQuery: retry(fetchBaseQuery({ baseUrl: "/" }), {
    maxRetries: 3,
  }),
  endpoints: (builder) => ({
    generateScenario: builder.query<
      Scenario,
      GenerateScenarioRequestBody | undefined
    >({
      query: (body) => ({
        url: `api/generateScenario`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useLazyGenerateScenarioQuery } = scenarioApi;
