import {
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Scenario, useFormOptions } from "../../types";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setScenario } from "../../store/conversationSlice";
import { useLazyGenerateScenarioQuery } from "../../services/scenarios";
import { useEffect } from "react";
import { ArrowBack, Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { ErrorDialog } from "../../components/ErrorDialog";
import { selectLanguage, selectUserId } from "../../store/appSlice";
import { useTranslation } from "react-i18next";

type FormValues = {
  scenario: Scenario;
};

const defaultScenario: Scenario = {
  personas: {
    user: {
      gender: "",
    },
    assistant: {
      gender: "",
    },
  },
  roleplay: {
    scenario: "",
  },
};

export const ScenarioWizard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserId);
  const { t } = useTranslation();
  const { genders } = useFormOptions();
  const language = useAppSelector(selectLanguage);

  const [
    trigger,
    { data: generatedScenario, isFetching, isError: generationErrored },
  ] = useLazyGenerateScenarioQuery();

  const { handleSubmit, control, reset } = useForm<FormValues>({
    defaultValues: {
      scenario: defaultScenario,
    },
  });

  const onSubmit = (values: FormValues) => {
    trigger({
      scenario: {
        ...values.scenario,
        roleplay: { ...values.scenario.roleplay, language },
      },
      userId,
    });
  };

  const manualCreation = () => {
    navigate("../configure", { state: { resetScenario: true } });
  };

  useEffect(() => {
    if (!generatedScenario) {
      return;
    }

    dispatch(setScenario(generatedScenario));
    navigate("../configure", { state: { hideGenerate: true } });
  }, [generatedScenario]);

  return (
    <>
      <Stack gap={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container alignItems="center">
          <Grid item xs={2} display="flex">
            <IconButton onClick={() => navigate("../")}>
              <ArrowBack />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h4" align="center" py={2}>
              {t("scenarioWizard.title")}
            </Typography>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="right">
            <IconButton
              onClick={() => {
                reset({
                  scenario: defaultScenario,
                });
              }}
            >
              <Refresh />
            </IconButton>
          </Grid>
        </Grid>

        <Typography variant="h5">
          {t("scenarioWizard.yourCharacter.title")}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {t("scenarioWizard.yourCharacter.subtitle")}
        </Typography>
        <Controller
          name="scenario.personas.user.gender"
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              exclusive
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
              disabled={isFetching}
            >
              {genders.map(({ label, value }) => (
                <ToggleButton key={value} value={value}>
                  {label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        />

        <Typography variant="h5" mt={2}>
          {t("scenarioWizard.theirCharacter.title")}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {t("scenarioWizard.theirCharacter.subtitle")}
        </Typography>
        <Controller
          name="scenario.personas.assistant.gender"
          control={control}
          render={({ field }) => (
            <ToggleButtonGroup
              disabled={isFetching}
              exclusive
              {...field}
              onChange={(_, value) => {
                field.onChange(value);
              }}
            >
              {genders.map(({ label, value }) => (
                <ToggleButton key={value} value={value}>
                  {label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}
        />

        <Typography variant="h5" mt={2}>
          {t("scenarioWizard.roleplay.title")}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {t("scenarioWizard.roleplay.subtitle")}
        </Typography>
        <Controller
          name="scenario.roleplay.scenario"
          control={control}
          render={({ field }) => (
            <TextField
              label={t("scenarioWizard.roleplay.description")}
              multiline
              minRows={3}
              disabled={isFetching}
              {...field}
            />
          )}
        ></Controller>

        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          disabled={isFetching}
          loading={isFetching}
        >
          {t("scenarioWizard.buttons.nextStep")}
        </LoadingButton>
        <Button fullWidth onClick={manualCreation} variant="outlined">
          {t("scenarioWizard.buttons.advancedCreation")}
        </Button>
      </Stack>

      <ErrorDialog
        open={generationErrored}
        message={t("scenarioWizard.errorMessage")}
      />
    </>
  );
};
