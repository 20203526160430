import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";
import { LanguageSelect } from "../../components/LanguageSelect";
import { Footer } from "../../components/Footer";

export const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack height={1} width={1} p={isMobile ? 0 : 3} alignItems="center">
      <Stack
        gap={2}
        maxWidth={900}
        width={1}
        borderRadius={3}
        border={isMobile ? undefined : "1px solid white"}
        p={4}
        mb={3}
      >
        <Outlet />
      </Stack>
      <Grid
        container
        maxWidth={900}
        width={1}
        justifyContent="center"
        pb={4}
        px={2}
        rowGap={1}
      >
        <Grid item sm={2} />
        <Grid item sm={8} display="flex" justifyContent="center" px={3}>
          <Footer />
        </Grid>
        <Grid item sm={2} display="flex" justifyContent="right">
          <LanguageSelect />
        </Grid>
      </Grid>
    </Stack>
  );
};
