import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./lang/en.json";
import ja from "./lang/ja.json";
import fr from "./lang/fr.json";
import es from "./lang/es.json";

const resources = {
  en,
  ja,
  fr,
  es,
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
