import { z } from "zod";
import { ScenarioSchema, MessageSchema } from "../functions/types";

export const ChatRequestBodySchema = z.object({
  messages: z.array(MessageSchema),
  scenario: ScenarioSchema.optional(),
  userId: z.string().optional(),
  sessionId: z.string().optional(),
});

export const GenerateScenarioRequestBodySchema = z.object({
  scenario: ScenarioSchema.optional(),
  userId: z.string().optional(),
  sessionId: z.string().optional(),
});

export type ChatRequestBody = z.infer<typeof ChatRequestBodySchema>;
export type GenerateScenarioRequestBody = z.infer<
  typeof GenerateScenarioRequestBodySchema
>;
